import React from 'react'   
import TD1 from './trackerdetect-cartoon1.png'
import TD2 from './trackerdetect-cartoon2.png'
import TD3 from './trackerdetect-cartoon3.png'
import TD4 from './trackerdetect-cartoon4.png'
import { Carousel } from '@signatu/common-react'

export default () => (
    <Carousel>
        <img src={TD1} />
        <img src={TD2} />
        <img src={TD3} />
        <img src={TD4} />
    </Carousel>
)
