import { QuestionAndAnswer } from '../../components/common/q-and-a'

const questionsAndAnswers: QuestionAndAnswer[] = [
    {
        question:
            'Does Signatu’s Trackerdetect discover and record when other companies are on my site and what they do on my site, as required by GDPR Articles 24.1 and 30?',
        answer:
            'Yes. This is described in our Trackerdetect Legal Guide. To access the guide, you need to have an account.',
    },
    {
        question: 'Can I monitor multiple sites together?',
        answer: 'Yes. You can group sites into Site Groups.',
    },
    {
        question: 'Can I scan a single URL?',
        answer: 'Yes.',
    },
    {
        question: 'Can I delegate responsibility to a specific person for companies on a specific page?',
        answer:
            'Yes. The Page Responsible will be able to edit the page, and receive notifications about new companies on your site.',
    },
    {
        question: 'Can I trigger profile-triggered companies to appear on my site by setting my own cookies?',
        answer: 'Yes.',
    },
    {
        question: 'Can I whitelist URLs with other companies on my site that I do not want to be flagged as unknown?',
        answer: 'Yes',
    },
    {
        question: 'Can I trigger geolocation-triggered companies to appear on my site?',
        answer:
            'Yes. Companies that appear on your site often use source IP addresses to conditionally load depending on the users’ geographical location. We support analyzing websites through a Signatu VPN that can originate traffic in different geographical locations.',
    },
    {
        question: 'Can I determine the scan/monitoring frequency?',
        answer: 'Yes.',
    },
    {
        question: 'Can I receive notification when 3rd parties are discovered on my site?',
        answer: 'Yes.',
    },
    {
        question: 'Can I notify my other systems (such as CRM) when other companies are discovered on my site?',
        answer:
            'Yes. We support sending events to Webhooks, Amazon Simple Queue Service (SQS) and Microsoft Azure Service Bus.',
    },
    {
        question:
            'Can I view which companies that are on my site and which resources they use on my site?',
        answer: 'Yes. The company information is also relevant to include in consent requests, legitimate interests, Privacy Policy, Subject Access Request Response, Data Protection Impact Assessment and Data Processing Record.',
    },
    {
        question:
            'Can I get a screenshot of the scanned URL to provide evidence of what the screen looked like at the time of the scan?',
        answer: 'Yes.',
    },
    {
        question: 'Can I view in which IFrames other companies on my site are on a scanned URL?',
        answer:
            'Yes. You can see who controls each IFRAME and which companies that are in each IFRAME. You can click on each IFRAME to view the IP address, geolocation and country of each detected company.',
    },
    {
        question: 'Can I view which cookies that are used on a URL?',
        answer:
            'Yes. You can see which cookies that are set, whether a cookie is a permanent cookie or a session cookie, whether a cookie is set by the domain or by another company on your site.',
    },
    {
        question: 'Can I view the geolocation of the website server of the site I scan/monitor?',
        answer:
            'Yes. This enables you to know the country where the site server is located, and if the location is outside the EU or EEA, a transfer of end user data outside the EU or EEA.',
    },
    {
        question: 'Can I view an interactive visual representation of all the detected companies on a URL?',
        answer:
            'Yes. The graph is currently a beta version. The graph shows a one-image-view of all the detected companies and the relations between the site and the detected companies.',
    },
    {
        question:
            'Can I get an overview in a Dashboard with all the detected companies at a URL/site in my scan period?',
        answer: 'Yes.',
    },
    {
        question: 'Can I use Trackerdetect to prove that my consent request was provided at a URL at a given time?',
        answer: 'Yes. Scan and make a screenshot of the URL daily. Screenshots are recorded and stored.',
    },
    {
        question:
            'Can Signatu help me include required information about other companies on my site in my Privacy Policy?',
        answer:
            'Yes. Discover other companies on your site automatically and use that information in our Privacy Policy service to inform end users that you use other companies on your site.',
    },
    {
        question: 'Can I use Trackerdetect to prove that my Privacy Policy was provided at a URL at a given time?',
        answer: 'Yes. Scan and make a screenshot of the URL daily. Screenshots are recorded and stored.',
    },
]

export default questionsAndAnswers
