/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import QandA from '../../components/common/q-and-a';
import Card from '../../components/content/card';
import questionsAndAnswers from './trackerdetect-q-and-a';
import TryitAction from '../../components/signup/tryit-action';
import {Carousel} from '@signatu/common-react';
import TrackerdetectCarousel from './trackerdetect-carousel';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(TrackerdetectCarousel), "\n", React.createElement(TryitAction), "\n", React.createElement(Card, {
    image: "trace.png",
    title: "Monitor"
  }, React.createElement(_components.p, null, "Trackerdetect analyzes your web sites automatically, identifying all set cookies, loaded scripts, tracking pixels\nand and IFrames, and mapping those resources to 500+ known 3rd party trackers. We schedule analyses automatically\nfor you and securely store the result data. As an added bonus, we also analyze the security of your web page.")), "\n", React.createElement(Card, {
    image: "eu-flag.png",
    imagePos: "right",
    title: "ePrivacy and GDPR compliance"
  }, React.createElement(_components.p, null, "Trackerdetect monitors your site for 3rd parties that may receive customer data and provides company information\nthat you can use in your Consent Requests, Privacy Policy, Data Processing Record, Data Protection Impact Assessment\nand more. Plus, the results are recorded to provide an audit trail.")), "\n", React.createElement(Card, {
    image: "icons/share.png",
    title: "Collaborate"
  }, React.createElement(_components.p, null, "Share results with your team to collaborate on Sites and Site Groups. Delegate responsibilties per page.")), "\n", React.createElement(Card, {
    image: 'icons/emailnotification.png',
    imagePos: "right",
    title: "Notify"
  }, React.createElement(_components.p, null, "We understand that you have other things to do than browser around Trackerdetect every morning. Signatu can send\nTrackerdetect events via e-mail, Webhooks, Amazon Simple Queue Service (SQS), and Microsoft Azure Service Bus.")), "\n", React.createElement(TryitAction), "\n", React.createElement(_components.h2, {
    id: "questions-and-answers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#questions-and-answers",
    "aria-label": "questions and answers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Questions and answers"), "\n", React.createElement(QandA, {
    data: questionsAndAnswers
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
